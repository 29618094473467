import { motion, useReducedMotion } from 'framer-motion';
import React, { useMemo } from 'react';
import { CustomCssProps } from '~/config';

/**
 * Animated highlight wrapper with optional fade in text
 */

type HighlightProps = CustomCssProps & {
  children?: React.ReactNode;
  delay?: number;
  duration?: number;
  bgColor?: string;
  color?: string;
  fadeText?: boolean;
  fadeTextDelay?: number;
  fadeTextDuration?: number;
};

export const Highlight = ({
  children,
  delay = 0,
  duration = 1,
  bgColor = 'yellow',
  color = 'black',
  fadeText = false,
  fadeTextDelay = 0,
  fadeTextDuration = 0,
  customCss,
}: HighlightProps) => {
  const prefersReducedMotion = useReducedMotion();

  // pre-memoize a string vesion of the customCss so that it won't re-trigger the
  // following useMemo and cause a re-render that can restart the highlight animation
  const cssCache = useMemo(
    () => customCss && JSON.stringify(customCss),
    [customCss],
  );

  return (
    <motion.div
      css={[cssCache && JSON.parse(cssCache)]}
      initial={{
        background: `linear-gradient(${bgColor}, ${bgColor}) left no-repeat`,
        backgroundSize: prefersReducedMotion ? '100% 100%' : '0% 100%',
        color: color,
      }}
      whileInView={{
        backgroundSize: '100% 100%',
      }}
      transition={{
        duration: duration,
        ease: 'easeInOut',
        delay: delay,
      }}
      viewport={{ once: true }}
    >
      {fadeText ? (
        <motion.span
          initial={{ opacity: prefersReducedMotion ? 1 : 0 }}
          whileInView={{ color: color, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: fadeTextDelay, duration: fadeTextDuration }}
        >
          {children}
        </motion.span>
      ) : (
        <> {children}</>
      )}
    </motion.div>
  );
};
