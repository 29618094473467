import React, { FC } from 'react';
import tw from 'twin.macro';

type HamburgerIconProps = {
  //
  state: 'open' | 'closed';
  handleClick: () => void;
};

export const HamburgerIcon: FC<HamburgerIconProps> = ({
  handleClick,
  state,
}) => {
  return (
    <button onClick={handleClick} tw="p-0 ml-3 h-16">
      <svg
        tw="h-5 my-2"
        viewBox="0 0 31 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="31"
          height="3"
          fill="currentColor"
          css={[
            tw`[transition:transform .25s ease-in-out] [transform-origin:2px 0%]`,
            state === 'open' && tw`[transform:rotate(43deg) translateX(2px)]`,
          ]}
        />
        <rect
          y="10.0542"
          width="31"
          height="3"
          fill="currentColor"
          css={[
            tw`[transition:opacity .25s ease-in-out]`,
            state === 'open' && tw`opacity-0`,
          ]}
        />
        <rect
          y="20.1079"
          width="31"
          height="3"
          fill="currentColor"
          css={[
            tw`[transition:transform .25s ease-in-out] [transform-origin:2px 100%]`,
            state === 'open' && tw`[transform:rotate(-43deg) translateX(2px)]`,
          ]}
        />
      </svg>
    </button>
  );
};
