import React from 'react';
import tw from 'twin.macro';
import { LinkPlus } from '~/components/common';
import FacebookIcon from '~/images/facebook.svg';
import InstagramIcon from '~/images/instagram.svg';
import TwitterIcon from '~/images/twitter.svg';

/**
 * Socal icons
 */

export type SocialIconsProps = {
  spacing?: 'desktop' | 'mobile';
};

export const SocialIcons = ({ spacing }: SocialIconsProps) => (
  <div
    tw="grid grid-cols-3 justify-center items-center"
    css={[
      spacing === 'desktop'
        ? tw`[grid-column:3] [justify-self:end] gap-2 [max-width:75px] lg:gap-4`
        : tw`gap-6`,
    ]}
  >
    <LinkPlus customCss={linkStyle} to="https://www.instagram.com/icaad_ngo/">
      <InstagramIcon css={iconStyle} />
    </LinkPlus>
    <LinkPlus customCss={linkStyle} to="https://twitter.com/icaadglobal">
      <TwitterIcon css={[iconStyle, tw`ml-0.5`]} />
    </LinkPlus>
    <LinkPlus customCss={linkStyle} to="https://facebook.com/icaadglobal">
      <FacebookIcon css={iconStyle} />
    </LinkPlus>
  </div>
);

const linkStyle = tw`md:([transition:all 0.25s var(--ease-out-back)] hover:(opacity-90 [transform:scale(1.4)]))`;

const iconStyle = tw`w-4 h-4 lg:([width:20px] [height:20px])`;
