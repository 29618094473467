import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { Category, Poem } from '~/config';
import { frontmatterWithSlug } from '~/utils';

export const usePoemsQuery = () => {
  const data = useStaticQuery(graphql`
    {
      cats: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/category/" } }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              color
            }
            fileAbsolutePath
          }
        }
      }
      poems: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/poems/" } }
        sort: { fields: frontmatter___case_name, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              category
              case_name
              short_name
              timeline_image {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              context_image {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              audio_file {
                publicURL
                extension
              }
              read_by
            }
            fileAbsolutePath
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const catNodes = frontmatterWithSlug<Partial<Category>>(data.cats).filter(
      (cat) => cat.slug !== 'all',
    );

    const poemNodes = frontmatterWithSlug<Partial<Poem>>(data.poems).map(
      (poem) => ({
        ...poem,
        timeline_image_data:
          poem.timeline_image?.image?.childImageSharp.gatsbyImageData,
        context_image_data:
          poem.context_image?.image?.childImageSharp.gatsbyImageData,
      }),
    );

    const poemsByCategory = catNodes.map((catNode) => ({
      ...catNode,
      poems: poemNodes.filter((poem) => poem.category === catNode.slug),
    }));

    return { catNodes, poemNodes, poemsByCategory };
  }, [data]);
};
