import tw, { styled } from 'twin.macro';
import { CustomCssProps } from '~/config';

/* Responsive Show/Hide Divs */

/**
 * Div that auto-hides if larger than `md:`
 *
 * Accepts `customCss` prop.
 */
export const MobileOnly = styled.div<CustomCssProps>(({ customCss }) => [
  customCss,
  tw`md:hidden`,
]);

const displays = ['block', 'grid', 'flex', 'inlineBlock'] as const; // expand as needed (requires updating component)
type Display = typeof displays[number]; // extrapolates union type from 'as const' array
type DesktopOnlyProps = CustomCssProps & { [key in Display]?: boolean };

/**
 * Div that auto-hides if smaller than `md:` breakpoint.
 *
 * Accepts `customCss` prop. To modify `display`, pass loose prop `grid`, `flex` or `inlineBlock`.
 */
export const DesktopOnly = styled.div<DesktopOnlyProps>(
  ({ inlineBlock, grid, flex, customCss }) => [
    customCss,
    tw`hidden md:block`,
    inlineBlock && tw`hidden md:inline-block`,
    grid && tw`hidden md:grid`,
    flex && tw`hidden md:flex`,
  ],
);

/* Horizontally Centered Div */

export const Centered = styled.div<{ fullHeight?: boolean }>(
  ({ fullHeight }) => [tw`grid justify-center`, fullHeight && tw`h-full`],
);

/* Full-screen layer with content centering options */

type FSOverlayProps = CustomCssProps & {
  hcenter?: boolean;
  vcenter?: boolean;
  center?: boolean;
  scrollable?: boolean;
};

export const FSOverlay = styled.div<FSOverlayProps>(
  ({ hcenter, vcenter, center, customCss, scrollable }) => [
    scrollable ? tw`relative` : tw`fixed overflow-hidden`,
    tw`w-screen h-screen`,
    // todo z-index option?
    (center || hcenter || vcenter) && tw`grid`,
    (center || hcenter) && tw`justify-center`,
    (center || vcenter) && tw`content-center`,
    customCss,
  ],
);

/** flex w/ standard left pad for desktop nav */
export const PageContainer = tw.div`flex p-8 md:(pl-36)`;

export const PageColumn = styled.div<CustomCssProps>(({ customCss }) => [
  tw`block m-auto [max-width:1024px] overflow-hidden md:(flex align-top)`,
  customCss,
]);
export const NavColumn = tw.div`hidden md:(flex [min-width:120px]) lg:[min-width:140px]`;
