import { isSSG } from '~/config';

// These strings map to classname prefixes in load-indicator.module.scss
export enum LoadIndicatorTheme {
  /**
   * Beware that this requires a separate bgColor config. Use it on
   * sites with a single stable bg color to avoid hassles.
   */
  spinner = 'spinner',

  threelines = 'threelines',
}

export type LoadIndicatorConfig = {
  /**
   * @default LoadIndicatorTheme.spinner
   */
  theme?: LoadIndicatorTheme;

  /**
   * Global - currently can't be changed per instance.
   * @default 'gray'
   */
  color?: string;

  /**
   * Global - currently can't be changed per instance.
   * The spinner requires a background color for its knockout area.
   * @default 'white'
   */
  bgColor?: string;

  /**
   * @default 0.4
   */
  scale?: number;
};

/**
 * You can either pass these config props to a LoadIndicator instance
 * or call this function once on app/page load to configure globally,
 * e.g. at the top of Layout.tsx if that's used in all pages.
 */
export const setupLoadIndicator = ({
  theme,
  color,
  bgColor,
  scale,
}: LoadIndicatorConfig) => {
  if (isSSG) {
    return;
  }

  if (theme) {
    defaultLoadIndicatorTheme = theme;
  }

  if (color) {
    document.documentElement.style.setProperty('--loader-color', color);
  }

  if (bgColor) {
    document.documentElement.style.setProperty('--loader-bg-color', bgColor);
  }

  if (scale) {
    document.documentElement.style.setProperty(
      '--loader-scale',
      scale.toString(),
    );
  }
};

// kept here to avoid circular import
export let defaultLoadIndicatorTheme = LoadIndicatorTheme.spinner;
