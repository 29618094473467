import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { SocialIcons } from '~/components/common';
import { AutumnalRainbow, sponsorSizes } from '~/components/home/home-content';
import { CustomCssProps } from '~/config';
import { navData } from '~/config/nav';
import { useScroll } from '~/hooks';
import { useSponsorQuery } from '~/hooks/useSponsorQuery';
import MobileLogo from '~/images/dicta-logo-mobile.svg';
import LinkIcon from '~/images/link-out.svg';
import { LinkPlus } from '..';
import { HamburgerIcon } from './Hamburger';

type MobileNavProps = CustomCssProps & {
  isPoemPage?: boolean;
  onMobileOpen: (boolean) => void;
  mobileOpen: boolean;
};

export const MobileNav: FC<MobileNavProps> = ({
  onMobileOpen,
  mobileOpen,
  isPoemPage,
  customCss,
}) => {
  const { isScrolled } = useScroll(20, undefined, true);

  const handleCloseNav = useCallback(() => onMobileOpen(false), [onMobileOpen]);

  const {
    projectByItems,
    projectByImgs,
    mostPreciousSponsors,
    mostPreciousSponsorsImgs,
  } = useSponsorQuery();

  const contentVariants = useMemo(
    () => ({
      closed: {
        height: '92px', // rainbow not included™
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
        },
      },
      open: {
        height: '100vh',
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
        },
      },
    }),
    [],
  );

  return (
    <div
      id="mobile-nav"
      css={[
        // z: above desktop nav, timeline items which are at 50
        tw`[z-index:60] fixed top-0 w-full [min-height:96px] shadow-lg [transition:all 0.3s ease-out]`,
        !mobileOpen &&
          isScrolled &&
          !isPoemPage &&
          tw`[transform:translateY(-98px)] shadow-none`,
        customCss,
      ]}
    >
      <motion.div
        variants={contentVariants}
        initial="closed"
        animate={mobileOpen ? 'open' : 'closed'}
        css={[
          tw`px-4 pt-2`,
          mobileOpen ? tw`overflow-y-auto` : tw`overflow-hidden`,
          !(isPoemPage && !mobileOpen) &&
            tw`bg-brand-gray-light text-brand-gray`,
          isPoemPage && !mobileOpen && tw`pointer-events-none`,
        ]}
      >
        {!(isPoemPage && !mobileOpen) && (
          <Top>
            <LinkPlus to="/timeline/" onClick={handleCloseNav}>
              <MobileLogo
                tw="[height:20vw] [max-height:4rem] mt-1"
                aria-label="Dicta logo"
              />
            </LinkPlus>

            <HamburgerIcon
              state={mobileOpen ? 'open' : 'closed'}
              handleClick={() => onMobileOpen(!mobileOpen)}
            />
          </Top>
        )}

        {mobileOpen && (
          <motion.div tw="bg-brand-gray-light text-brand-gray">
            <Navbar>
              {navData.map((item) => (
                <LinkPlus
                  key={item.to}
                  to={item.to}
                  customCss={linkStyle}
                  onClick={handleCloseNav}
                >
                  {item.text}
                  {item.to.includes('http') && <LinkIcon />}
                </LinkPlus>
              ))}
            </Navbar>

            <Bottom>
              <LogoContainer>
                {projectByItems.map(
                  (node, i) =>
                    projectByImgs[i] && (
                      <Author key={node.slug}>
                        <Span>
                          {node.level === 'projectBy' ? 'Project' : 'Powered'}{' '}
                          by:
                        </Span>
                        <LinkPlus to={node.url} onClick={handleCloseNav}>
                          <GatsbyImage
                            tw="[max-width:150px] [max-height:calc(150px * 0.3)] md:([max-width:200px] [max-height:calc(200px * 0.3)])"
                            image={projectByImgs[i]!}
                            objectFit="contain"
                            objectPosition="left"
                            alt={node.title}
                          />
                        </LinkPlus>
                      </Author>
                    ),
                )}
              </LogoContainer>

              <div>
                <Span>With generous support from:</Span>
                <LogoContainer>
                  {mostPreciousSponsors.map((node, i) => {
                    const level = parseInt(node.level, 10);
                    const sponsorSize = sponsorSizes(level, 3, 0, 65, 120);

                    return (
                      mostPreciousSponsorsImgs[i] && (
                        <LinkPlus
                          key={node.slug}
                          to={node.url}
                          onClick={handleCloseNav}
                        >
                          <GatsbyImage
                            css={[sponsorSize, tw`mx-0`]}
                            image={mostPreciousSponsorsImgs[i]!}
                            objectFit="contain"
                            alt={node.title}
                          />
                        </LinkPlus>
                      )
                    );
                  })}
                </LogoContainer>
              </div>
            </Bottom>

            <div tw="pt-14 flex justify-center">
              <SocialIcons spacing="mobile" />
            </div>

            <div tw="h-48" />
          </motion.div>
        )}
      </motion.div>

      {!isPoemPage && (
        <AutumnalRainbow tw="absolute bottom-0 inset-x-0 h-[5px] shadow-md md:(shadow-none h-2)" />
      )}
    </div>
  );
};

const Navbar = tw.div`flex flex-col gap-4 pt-8 pb-6`;
const Bottom = tw.div``;
const Top = tw.div`flex justify-between`;
const Author = tw.div`flex flex-col gap-4`;
const linkStyle = tw`flex font-medium capitalize relative py-1 pl-2 border border-solid border-brand-coral [max-width:11rem]`;

const Span = tw.span`text-xs`;

const LogoContainer = tw.div`flex justify-between items-center mt-4 landscape:(justify-start gap-10)`;
