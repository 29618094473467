import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import tw, { styled } from 'twin.macro';
import { SocialIcons } from '~/components/common';
import { CustomCssProps } from '~/config';
import { useSponsorQuery } from '~/hooks/useSponsorQuery';
import { DesktopOnly, LinkPlus } from '.';
import { AutumnalRainbow, sponsorSizes } from '../home/home-content';

type FooterProps = CustomCssProps & {
  sticky?: boolean;
};

export const Footer: FC<FooterProps> = ({ customCss, sticky }) => {
  const {
    projectByItems,
    projectByImgs,
    mostPreciousSponsors,
    mostPreciousSponsorsImgs,
  } = useSponsorQuery();

  return (
    <DesktopOnly>
      <MakeUpPadding />
      <OuterContainer css={customCss} sticky={sticky}>
        <AutumnalRainbow tw="-mt-2" />
        <Container>
          <ProjectPoweredBy>
            {projectByItems.map(
              (node, i) =>
                projectByImgs[i] && (
                  <Sponsor key={node.slug}>
                    <Span>
                      {node.level === 'projectBy' ? 'Project' : 'Powered'} by:
                    </Span>
                    <LinkPlus to={node.url}>
                      <GatsbyImage
                        tw="[max-width:80px] [max-height:calc(120px * 0.3)] lg:([max-width:150px] [max-height:calc(150px * 0.3)])"
                        image={projectByImgs[i]!}
                        objectFit="contain"
                        objectPosition="left"
                        alt={node.title}
                      />
                    </LinkPlus>
                  </Sponsor>
                ),
            )}
          </ProjectPoweredBy>
          <Supporters>
            <Span tw="mr-1.5">Sponsored by:</Span>
            <LogoContainer>
              {mostPreciousSponsors.map((node, i) => {
                const level = parseInt(node.level, 10);
                const sponsorSize = sponsorSizes(level, 3, 0, 20, 150, 1080);

                const sponsorImage = (
                  <GatsbyImage
                    css={sponsorSize}
                    image={mostPreciousSponsorsImgs[i]!}
                    objectFit="contain"
                    alt={node.title}
                  />
                );

                return (
                  mostPreciousSponsorsImgs[i] &&
                  (node.url ? (
                    <LinkPlus key={node.title} to={node.url} tw="inline-flex">
                      {sponsorImage}
                    </LinkPlus>
                  ) : (
                    <div key={node.title} tw="inline-flex">
                      {sponsorImage}
                    </div>
                  ))
                );
              })}
            </LogoContainer>
          </Supporters>
          <SocialIcons spacing="desktop" />
        </Container>
      </OuterContainer>
    </DesktopOnly>
  );
};

const OuterContainer = styled.div<{ sticky?: boolean }>(({ sticky }) => [
  tw`bg-brand-gray-light bottom-0 w-full [height:80px] py-2`,
  sticky ? tw`fixed` : tw`absolute`,
]);
const Container = tw.div`grid [grid-template-columns:max-content auto max-content] [grid-column-gap:1rem] px-2 max-w-7xl mx-auto h-full justify-items-center`;
const MakeUpPadding = tw.div`[min-height:80px] pt-10`;

const ProjectPoweredBy = tw.div`grid [grid-template-columns:max-content max-content] justify-start`;
const Supporters = tw.div`grid [grid-template-columns:25% auto] items-center justify-end`;

const Sponsor = tw.div`flex flex-row gap-4 items-center`;

const Span = tw.span`text-xs [line-height:1] text-right text-brand-gray`;
const LogoContainer = tw.div`flex items-center w-min`;
