import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import tw from 'twin.macro';
import { LinkPlus } from '~/components/common';
import { useSponsorQuery } from '~/hooks/useSponsorQuery';

type SponsorsProps = {
  fadeOut?: boolean;
  delay?: number;
  duration?: number;
  onComplete?: () => void;
};

export const Sponsors: FC<SponsorsProps> = ({
  fadeOut,
  delay = 0,
  duration = 2,
  onComplete,
}) => {
  const { query, projectByItems, projectByImgs } = useSponsorQuery();
  const motionProps = (itemDelay: number) => ({
    initial: { opacity: 0 },
    animate: { opacity: fadeOut ? [0, 1, 1, 0] : 1 },
    transition: {
      delay: itemDelay,
      duration,
      times: fadeOut ? [0, 0.4, 0.9, 1] : undefined,
    },
  });
  return (
    <Container>
      <ProjectByContainer>
        {projectByItems.map(
          (node, i) =>
            projectByImgs[i] && (
              <motion.div
                key={node.slug}
                tw="h-full flex flex-col items-center justify-center"
                {...motionProps(delay + i * 1)}
              >
                <Heading>
                  {node.level === 'projectBy' ? 'Project' : 'Powered'} by:
                </Heading>
                <LinkPlus to={node.url}>
                  <GatsbyImage
                    tw="[max-width:150px] [max-height:calc(150px * 0.4)] md:([max-width:300px] [max-height:calc(300px * 0.4)])"
                    image={projectByImgs[i]!}
                    objectFit="contain"
                    alt={node.title}
                  />
                </LinkPlus>
              </motion.div>
            ),
        )}
      </ProjectByContainer>

      <motion.div {...motionProps(delay + 2)}>
        <Heading>With generous support from:</Heading>
      </motion.div>

      {query.sponsors.map((level, i) => {
        const levelStr = level[0].level;
        let levelMult = parseInt(levelStr.split(':').shift()!, 10) - 1;
        if (levelMult > 0) {
          levelMult++; // extra step to emphasize platinum
        }
        const width = Math.max(60, 300 - levelMult * 50);
        const minHeight = width * 0.4;
        return (
          <LevelContainer key={levelStr}>
            {level.map((node) => {
              if (node.logo) {
                const image = getImage(node.logo);
                image && (
                  <motion.div
                    key={node.slug}
                    css={[
                      tw`grid content-center justify-center`,
                      { width, minHeight },
                    ]}
                    onAnimationComplete={
                      i === query.sponsors.length - 1 ? onComplete : undefined
                    }
                    {...motionProps(delay + 4 + i * 0.4)}
                  >
                    <LinkPlus to={node.url}>
                      <GatsbyImage
                        key={node.title}
                        image={image}
                        alt={node.title}
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </LinkPlus>
                  </motion.div>
                );
              }
            })}
          </LevelContainer>
        );
      })}
    </Container>
  );
};

const Heading = tw.h3`text-xs`;
const Container = tw.div`m-4 flex [flex-direction:column] justify-center items-center`;
const ProjectByContainer = tw.div`flex flex-wrap gap-4 md:gap-12`;
// centering affects mobile
const LevelContainer = tw.div`flex flex-wrap gap-8 justify-center items-center`;
