import React, { useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { LinkPlus } from '~/components/common';
import { Poem } from '~/config';
import FacebookIcon from '~/images/facebook.svg';
import InstagramIcon from '~/images/instagram.svg';
import TwitterIcon from '~/images/twitter.svg';
import { encodeQueryString } from '~/utils';

/**
 * Static Sharing icons list, used on mobile only for this site
 */

export type ShareIconsProps = {
  spacing?: 'desktop' | 'mobile';
  poem: Poem;
};

export const ShareIcons = ({ spacing, poem }: ShareIconsProps) => {
  const links = useMemo(
    () => ({
      twitter:
        poem.tweet &&
        'https://twitter.com/share?' +
          encodeQueryString({
            text: poem.tweet,
          }),
      facebook:
        poem.fb_post &&
        'https://www.facebook.com/sharer/sharer.php?' +
          encodeQueryString({
            display: 'page',
            u: poem.permalink,
            href: poem.permalink,
            quote: poem.fb_post,
            hashtag: poem.fb_hashtag,
          }),
      instagram: poem.instagram_url,
    }),
    [poem],
  );

  return (
    <ShareBar>
      {links.twitter && (
        <LinkPlus customCss={linkStyle} to={links.twitter}>
          <TwitterIcon css={[iconStyle, tw`ml-0.5`]} />
        </LinkPlus>
      )}

      {links.facebook && (
        <LinkPlus customCss={linkStyle} to={links.facebook}>
          <FacebookIcon css={iconStyle} />
        </LinkPlus>
      )}
      {links.instagram && (
        <LinkPlus customCss={linkStyle} to={links.instagram}>
          <InstagramIcon css={iconStyle} />
        </LinkPlus>
      )}
    </ShareBar>
  );
};

const ShareBar = tw.div`w-full flex justify-center items-center p-3 gap-4 md:p-6`;

const linkStyle = tw``;
// tw`md:([transition:all 0.25s var(--ease-out-back)] hover:(opacity-90 [transform:scale(1.4)]))`;

const iconStyle = [
  tw`h-[27px] w-[27px]`,
  css`
    path {
      fill: white;
    }
    &:hover {
      path {
        fill: rgb(var(--brand-coral));
      }
    }
  `,
];
