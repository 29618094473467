import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';
import { isDev, Sponsor } from '~/config';
import { frontmatterWithSlug } from '~/utils';

export const sponsorLevels = [
  '00:platinum-plus',
  '01:platinum',
  '02:gold',
  '03:silver',
  '04:bronze',
  '05:partner',
] as const;

const dev_spoofAllLevels = false;

export type SponsorLevel = typeof sponsorLevels[number];
export const isSponsorLevel = (data): data is SponsorLevel =>
  typeof data === 'string' && sponsorLevels.includes(data as SponsorLevel);

export const otherLevels = ['projectBy', 'poweredBy'] as const;
export type OtherLevel = typeof otherLevels[number];
export const isOtherLevel = (data): data is OtherLevel =>
  typeof data === 'string' && otherLevels.includes(data as OtherLevel);

const spoofLevel = (nodes: Sponsor[], level: number): Sponsor[] =>
  nodes.map((s) => {
    const _level = sponsorLevels[level];
    if (isSponsorLevel(_level) || isOtherLevel(_level)) {
      return {
        ...s,
        level: _level,
      };
    }
    return {
      ...s,
      level: _level,
    };
  });

export const useSponsorQuery = () => {
  const data = useStaticQuery(graphql`
    {
      sponsors: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/sponsor/" } }
        sort: { fields: [frontmatter___level, frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              logo {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              level
              individual
              order
              url
            }
            fileAbsolutePath
          }
        }
      }
    }
  `);

  return useMemo(() => {
    let allNodes = frontmatterWithSlug<Sponsor>(data.sponsors);
    const projectByLogo = allNodes.find((node) => node.level === 'projectBy')!;
    const poweredByLogo = allNodes.find((node) => node.level === 'poweredBy')!;
    const sponsorNodes = allNodes.filter(
      (node) => node.level !== 'projectBy' && node.level !== 'poweredBy',
    );
    const levels = new Set<string>();
    sponsorNodes.forEach((node) => levels.add(node.level));
    const byLevel = Array.from(levels).map((level) =>
      sponsorNodes.filter((node) => node.level === level),
    );
    const query = {
      projectByLogo,
      poweredByLogo,
      sponsors:
        dev_spoofAllLevels && isDev
          ? [
              spoofLevel(byLevel[0], 0),
              spoofLevel(byLevel[1], 1),
              spoofLevel(byLevel[1], 2),
              spoofLevel(byLevel[1], 3),
              spoofLevel(byLevel[1], 4),
            ]
          : byLevel,
    };

    const mostPreciousSponsors = query.sponsors
      .filter((level) => {
        const levelStr = level[0].level;
        const l = parseInt(levelStr, 10) - 1;
        return l < 3; // <3 ... coincidence???
      })
      .flat()
      .filter((sponsor) => !sponsor.individual);

    const mostPreciousSponsorsImgs = mostPreciousSponsors.map(
      (item) => item.logo && getImage(item.logo),
    );

    const individualSponsorsByLevel = query.sponsors.map((level) => {
      return level.filter((sponsor) => {
        if (sponsor.individual) {
          return sponsor;
        }
      });
    });

    const projectByItems = [query.projectByLogo, query.poweredByLogo];
    const projectByImgs = projectByItems.map(
      (item) => item.logo && getImage(item.logo),
    );

    return {
      query,
      mostPreciousSponsors,
      mostPreciousSponsorsImgs,
      projectByImgs,
      projectByItems,
      individualSponsorsByLevel,
    };
  }, [data]);
};
