import { Interpolation, Theme } from '@emotion/react';
import { Link } from 'gatsby';
import React, { CSSProperties, FC, ReactNode } from 'react';
import tw from 'twin.macro';
import { isExternalUrl } from '~/utils';

export type LinkPlusProps = {
  to?: string; // can be omitted if you're passing `onClick`
  onClick?: (e?) => void;

  /**
   * For normal cases just pass children. This supports titles from JSON.
   */
  title?: string;
  /**
   * Renders inside the link and can wrap or replace the
   * generated title element(s)
   */
  wrapper?: (titleChildren: ReactNode) => ReactNode;

  customCss?: Interpolation<Theme>; // additional styles in any Twin format (css, tw, style object, array)
  selectedStyles?: CSSProperties; // For use with Gatsby Link's `activeStyle` prop
  activeClassName?: string;
  nonDraggable?: boolean;
};

/**
 * Gatsby Link (internal) or external (<a>) link.
 *
 * For normal cases just pass children for literal cases. If you're rendering
 * links from JSON data, you can use the `title` and `wrapper` props instead,
 * which include support line-break support via `\n`'s in the title string.
 *
 * The spl002 project extends this with additional functionality that can be ported:
 * - transition-before-trigger ExitContext
 * - CTA components that wrap this simple base component to create icon buttons, etc.
 */
export const LinkPlus: FC<LinkPlusProps> = ({
  to,
  title,
  onClick,
  wrapper,
  customCss,
  selectedStyles,
  activeClassName,
  nonDraggable,
  children,
}) => {
  let titleJSX: ReactNode = children || title;

  // Multi-line title: break into divs with classes `.link-title.line-1`, etc.
  // Done uniformly so class will be applied to single-line items as well.
  if (typeof titleJSX === 'string') {
    titleJSX = titleJSX.split(/\\n/i).map((line, i) => (
      <div key={`line-${i + 1}`} className={`link-title line-${i + 1}`}>
        {line}
      </div>
    ));
  }

  if (wrapper) {
    titleJSX = wrapper(titleJSX);
  }

  // inline-block default: prevents cta containers from expanding out
  const styles = [tw`inline-block`, customCss];

  const link = to || '#';
  if (isExternalUrl(link)) {
    return (
      <a
        draggable={!nonDraggable}
        css={styles}
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        {titleJSX}
      </a>
    );
  }

  return (
    <Link
      partiallyActive={to !== '/'} // makes the nav highlights work correctly after page refresh
      css={styles}
      activeStyle={selectedStyles}
      activeClassName={activeClassName}
      to={link}
      onClick={onClick}
      draggable={!nonDraggable}
    >
      {titleJSX}
    </Link>
  );
};
