import React, { FC, useCallback, useContext } from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import {
  AudioFile,
  AUDIO_VOLUME,
  CustomCssProps,
  LocalStorageKey,
} from '~/config';
import { AudioContext } from '~/context/AudioContext';
import { storeSessionBoolean } from '~/utils';
import SpeakerOff from './speaker-off.svg';
import Speaker from './speaker.svg';

export type AudioPlayerProps = {
  audio?: AudioFile;
  readBy?: string;
  right?: boolean;
  dark?: boolean;
};

export const AudioPlayer: FC<CustomCssProps & AudioPlayerProps> = ({
  audio,
  readBy,
  right,
  dark,
  customCss,
}) => {
  const { play, pause, isPlaying } = useContext(AudioContext);

  const toggleAudioPlay = useCallback(() => {
    // session storage is updated on user action only
    // (used by the autoplay routine in poem template)
    storeSessionBoolean(LocalStorageKey.audioMuted, isPlaying);

    if (!isPlaying && audio?.publicURL) {
      play(audio.publicURL, AUDIO_VOLUME);
    } else if (isPlaying) {
      pause();
    }
  }, [audio, isPlaying, pause, play]);

  const Icon = isPlaying ? Speaker : SpeakerOff;
  const label = 'Listen to this poem' + readBy ? `, read by ${readBy}` : '';

  return (
    <Button
      aria-label={label}
      onClick={toggleAudioPlay}
      right={right}
      css={customCss}
    >
      <Icon css={[iconCss, dark && darkFill]} />
      {readBy && <Caption dark={dark}>Read by {readBy}</Caption>}
    </Button>
  );
};

const Button = styled.button<{ right?: boolean }>(({ right }) => [
  tw`flex justify-center items-center gap-1.5`,
  right && tw`flex-row-reverse`,
]);

const Caption = styled.div<{ dark?: boolean }>(({ dark }) => [
  tw`text-sm mt-px`,
  dark ? tw`text-brand-gray` : tw`text-brand-gray-light`,
]);

const iconCss = tw`h-6 pt-1 md:([height:2rem] [width:2rem]) [transition: all 0.3s linear]`;

const darkFill = css`
  path {
    transition: all 0.3s linear;
    fill: ${theme`colors.brand-gray`};
  }
`;
