import tw from 'twin.macro';

export * from './AudioPlayer';
export * from './Highlight';
export * from './layout';
export * from './LinkPlus';
export * from './LoadIndicator';
export * from './social';
export * from './transitions';

export const poemHeaderHeightCss = tw`
  [height: 166px] landscape:([height: 92px]) // mobile
  lg:(landscape:([height: 166px])) // desk (lg used so mobile landscape still takes on larges phones)
`;

export const headingCss = tw`
  font-secondary font-medium [font-size: 2rem /* see About on mobile */] mb-4 leading-none tracking-tight 
  md:([font-size: 2.5rem] tracking-normal 
      -mt-1.5 /* helps with aligning to top of photos */ mb-2)`;

export const highlightProps = {
  delay: 0.5,
  duration: 0.75,
  color: 'white',
  fadeText: false,
  customCss: [tw`px-2 mb-2.5 pt-3.5 pb-1`],
};

export const mobileAudioPlayerCss = tw`[background:#A53686] w-full mt-1 py-1 gap-1 px-1 text-xs font-primary text-white tracking-wider`;
