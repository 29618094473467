import { motion, AnimatePresence, Variants } from 'framer-motion';
import React, { FC, useCallback, useMemo } from 'react';
import { isMobileOnly as _isMobileOnly } from 'react-device-detect';
import tw from 'twin.macro';
import {
  AudioPlayer,
  AudioPlayerProps,
  mobileAudioPlayerCss,
} from '~/components/common';
import { AutumnalRainbow } from '~/components/home/home-content';
import { useHasMounted } from '~/hooks';
import DesktopLogo from '~/images/dicta-logo-desktop.svg';
import { LinkPlus } from '.';
import { MobileNav } from './Navigation/MobileNav';
import { CustomCssProps } from '~/config';
import MobileLogo from '~/images/dicta-logo-mobile.svg';
import { HamburgerIcon } from './Navigation/Hamburger';
import { poemHeaderHeightCss } from './index';
import { getPageExitClick } from './transitions/page-exit';

export type HeaderThemes = 'black' | 'blackNoBg' | 'grey';

type HeaderProps = CustomCssProps &
  AudioPlayerProps & {
    isPoemPage?: boolean;
    isPoemPageScrolled?: boolean;
    onMobileOpen: (boolean) => void;
    mobileOpen: boolean;
    caseName?: string; // for poem page only
    caseSubtitle?: string; // for poem page only
  };

export const Header: FC<HeaderProps> = ({
  onMobileOpen,
  mobileOpen,
  isPoemPage,
  isPoemPageScrolled,
  audio,
  readBy,
  caseName,
  caseSubtitle,
  customCss,
}) => {
  const hasMounted = useHasMounted();
  const isMobileOnly = hasMounted && _isMobileOnly;

  const handleCloseNav = useCallback(() => onMobileOpen(false), [onMobileOpen]);

  const poemHeaderVariants = useMemo(
    () =>
      ({
        // see also: perspective, tranform-origin below
        out: {
          y: 120,
          scaleY: 2,
          rotateX: -30,
          transition: {
            duration: 0.3,
            ease: 'linear',
          },
        },
        in: {
          y: 0,
          scaleY: 1,
          rotateX: 0,
          transition: {
            duration: 0.5,
            ease: 'easeOut',
          },
        },
      } as Variants),
    [],
  );

  const poemMobileAudioVariants = useMemo(
    () =>
      ({
        initial: {
          opacity: 0,
          y: 20,
        },
        animate: {
          opacity: 1,
          y: 0,
          transition: { ease: 'easeInOut', delay: 0.3 },
        },
        exit: {
          opacity: 0,
          y: 20,
        },
      } as Variants),
    [],
  );

  return !hasMounted ? null : (
    <motion.div
      id="main-header"
      layout
      css={[
        tw`[z-index:60] overflow-hidden sticky top-0 left-0 w-full [transition: all 0.4s ease-in-out]`,
        isPoemPageScrolled
          ? [poemHeaderHeightCss, tw`bg-black`]
          : tw`[height: 92px]`,
        isPoemPage && !isPoemPageScrolled && tw`bg-brand-gray-light`,
        !isPoemPage && tw`md:(bg-brand-gray-light)`, // special condition omits bg on mobile since bar rolls up
        // pointer-events-none unblocks text & UI under transparent area, reset below
        !isPoemPage && tw`pointer-events-none`,
        customCss,
      ]}
    >
      {isMobileOnly && !(isPoemPage && !mobileOpen) && (
        // Mobile Nav (works as overlay only on poem page, but header is also drawn below)
        <MobileNav
          isPoemPage={isPoemPage}
          onMobileOpen={onMobileOpen}
          mobileOpen={mobileOpen}
          customCss={tw`pointer-events-auto`}
        />
      )}

      {(!isMobileOnly || isPoemPage) && (
        // Desktop Nav (and Mobile header on poem page, overlay menu provided aboves)
        <div
          css={[
            tw`absolute inset-0 flex items-center justify-between px-4 gap-1 shadow-lg py-2 md:(gap-3 pt-3 pb-6)`,
            isPoemPageScrolled
              ? tw`text-white shadow-none`
              : tw`text-brand-gray`,
          ]}
        >
          <AutumnalRainbow tw="absolute bottom-0 inset-x-0 h-[5px] shadow-md md:(shadow-none)" />

          {/* Kinda goofy to duplicate the mobile nav here but it allows for the inline poem case header. A nice refactor might be to keep all top bar stuff here and take it out of MobileNav. */}
          {isMobileOnly ? (
            <LinkPlus
              to="/timeline/"
              onClick={getPageExitClick('/timeline/', {
                onClick: handleCloseNav,
              })}
            >
              <MobileLogo
                tw="h-12 mt-1 /* reduced to make room for case header */"
                aria-label="Dicta logo"
              />
            </LinkPlus>
          ) : (
            <LinkPlus
              to="/timeline/"
              onClick={getPageExitClick('/timeline/')}
              customCss={tw`pointer-events-auto`}
            >
              <DesktopLogo tw="w-[242px] h-10" aria-label="Dicta logo" />
            </LinkPlus>
          )}

          <AnimatePresence>
            {isPoemPageScrolled && (
              <div tw="flex flex-col [perspective: 1000px]">
                <CaseHeader
                  variants={poemHeaderVariants}
                  initial="out"
                  animate="in"
                  exit="out"
                >
                  <H3>{caseName}</H3>
                  <span>{caseSubtitle}</span>
                </CaseHeader>
                {isMobileOnly && audio && readBy && (
                  <motion.div
                    variants={poemMobileAudioVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    tw="m-0"
                  >
                    <AudioPlayer
                      audio={audio}
                      readBy={readBy}
                      customCss={mobileAudioPlayerCss}
                    />
                  </motion.div>
                )}
              </div>
            )}
          </AnimatePresence>

          {isMobileOnly && (
            <HamburgerIcon
              state={mobileOpen ? 'open' : 'closed'}
              handleClick={() => onMobileOpen(!mobileOpen)}
            />
          )}
          {audio && !isMobileOnly && (
            <AudioPlayer
              right
              dark={!isPoemPageScrolled}
              audio={audio}
              readBy={readBy}
              customCss={[
                tw`pointer-events-auto w-[242px] text-right text-sm mt-5 gap-4 `,
              ]}
            />
          )}
        </div>
      )}
    </motion.div>
  );
};

// Based on same styles in poem-template
const CaseHeader = tw(motion.div)`
  landscape:(hidden) lg:(landscape:(block)) // (lg used so mobile landscape still takes on larges phones)
  [max-width: 350px] [max-height: 150px]  origin-top px-2 py-1 text-xs lg:(text-base px-4 py-2 mr-0) 
  [border-width: 1px] border-solid border-brand-coral text-center text-brand-coral font-secondary
`;

// Based on same styles in poem-template
// mobile font-size based on /poem/cumming-v-richmond-county-board-of-education @ iPhone 12
const H3 = tw.h3`[transition:all 1s ease-in-out] [font-size: 1rem] [line-height: 1.2rem] sm:([font-size: 1.25rem] leading-tight) md:([font-size: 1.65rem] [line-height: 2rem]) text-white`;
