import { NavItemData } from '.';

export const navData: NavItemData[] = [
  {
    text: 'home',
    to: '/',
  },
  {
    text: 'timeline',
    to: '/timeline',
  },
  {
    text: 'poems',
    to: '/poems',
  },
  {
    text: 'about',
    to: '/about',
  },
  {
    text: 'donate',
    to: '/donate',
  },
  {
    text: 'shop',
    to: 'https://shop.icaad.ngo',
  },
];
