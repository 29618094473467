import { RefObject, useEffect, useRef, useState } from 'react';
import { isSSG } from '~/config/consts';

/**
 * Provides states hasScrolled (past threshold once),
 * isScrolled (toggles at threshold), and optionally only
 * sets isScrolled when scrolling down, for nav autohide.
 *
 * @param threshold minimum y distance before states change
 * @param scrollElRef if scrolling is within a div, pass a ref object
 * @param downOnly scrolling up anywhere will reset isScrolled to false
 * @returns { isScrolled: boolean, hasScrolled: boolean }
 */
export function useScroll(
  threshold = 0,
  scrollElRef?: RefObject<HTMLDivElement>,
  downOnly = false,
) {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const prevY = useRef<number>();

  useEffect(() => {
    if (isSSG) {
      return;
    }
    const handleScroll = ({ currentTarget }) => {
      const y =
        currentTarget.scrollY !== undefined
          ? currentTarget.scrollY
          : currentTarget.scrollTop;

      const isCancel =
        downOnly && prevY.current !== undefined && y < prevY.current;

      if (
        !isScrolled &&
        y > threshold &&
        !(downOnly && prevY.current === undefined) && // skips first downOnly check to avoid blink
        !isCancel
      ) {
        setHasScrolled(true);
        setIsScrolled(true);
      } else if (isScrolled && (y <= threshold || isCancel)) {
        setIsScrolled(false);
      }

      prevY.current = y;
    };

    const _target = scrollElRef?.current ?? window;
    _target.addEventListener('scroll', handleScroll, false);

    return () => {
      _target.removeEventListener('scroll', handleScroll, false);
    };
  }, [isScrolled, scrollElRef, threshold, downOnly]);

  return {
    hasScrolled,
    isScrolled,
  };
}
