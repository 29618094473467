import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { Poem } from '~/config';
import { frontmatterWithSlug, mapFrontmatter } from '~/utils';

/**
 * Sorted by year, with `category` as title string
 */
export const useTimelineQuery = () => {
  const data = useStaticQuery(graphql`
    {
      globals: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/strings/global/" } }
      ) {
        edges {
          node {
            frontmatter {
              desk_drag_hint
            }
          }
        }
      }
      cats: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/category/" } }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              color
            }
            fileAbsolutePath
          }
        }
      }
      poems: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/poems/" } }
        sort: { fields: frontmatter___case_year, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              category
              case_name
              short_name
              case_year
              timeline_image {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            fileAbsolutePath
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const globalStrings = mapFrontmatter<{
      desk_drag_hint: string;
    }>(data.globals)[0];

    const catNodes = frontmatterWithSlug<{
      title: string;
      slug: string;
      color: string;
    }>(data.cats);

    const nodes = frontmatterWithSlug<Partial<Poem>>(data.poems).map((node) => {
      return {
        ...node,
        category: catNodes.find((catNode) => catNode.slug === node.category)
          ?.slug,
        categoryColor: catNodes.find(
          (catNode) => catNode.slug === node.category,
        )?.color,
        timeline_image_data:
          node.timeline_image?.image.childImageSharp.gatsbyImageData,
      };
    });

    return { globalStrings, nodes, catNodes };
  }, [data]);
};
