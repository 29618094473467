import { useReducedMotion } from 'framer-motion';
import React, {
  Children,
  cloneElement,
  CSSProperties,
  FC,
  isValidElement,
} from 'react';
import { IntersectionOptions, InView } from 'react-intersection-observer';
import { Transition } from 'react-transition-group';
import { TwinCSS } from '~/config';
import { usePageExit } from './page-exit';

async function loadPolyfills() {
  if (
    typeof window !== 'undefined' &&
    typeof window.IntersectionObserver === 'undefined'
  ) {
    await import('intersection-observer');
  }
}
loadPolyfills();

const defaults = {
  seconds: 0.5,
  enterEasing: 'ease-out',
  enterEasingShift: 'ease-out',
  exitEasing: 'ease-out',
  intersectionOptions: { triggerOnce: true, rootMargin: '0px 0px 0px 0px' },
  // ^ iOS < 14 seems to require this default rootMargin
};

export type FadeProps = {
  // -- Animation Props --

  seconds?: number;
  delaySeconds?: number;
  enterEasing?: string;
  exitEasing?: string;
  /**
   * Stagger effect for multiple children. Value is a percent of fade duration.
   *
   * Examples:
   * - 1: back-to-back
   * - 0.8: slight overlap
   * - 1.2: extra delay between each item
   */
  stagger?: number;

  // Addtional options for fade-and-___ transitions.
  // Note that Fade uses an in / reverse-out paradigm for simplicity,
  // if you need more detail use react-transition-group directly.
  xShift?: number | string; // start only
  yShift?: number | string; // start only
  xScale?: number | string; // start only
  yScale?: number | string; // start only
  customTransform?: string; // start only
  customStyles?: CSSProperties; // start only
  /**
   * Applied to all children when wrappers are added. You can also use
   * `fade-wrapper-css` on individual child elements.
   */
  wrapperCss?: TwinCSS;

  // -- Trigger Props --

  /**
   * State-based trigger. Even when true, fade will start when the component is scrolled
   * into view, which can be overridden using `intersectionOptions`.
   * @default true
   * @see intersectionOptions
   */
  isShowing?: boolean;
  /**
   * By default each child will fade once in view.
   *
   * To disable observers pass `skip: true`.
   *
   * To always replay effects on scroll up/down pass `triggerOnce: false`.
   * (Fade defaults this option to true.)
   *
   * Other options like `threshold` can also fine-tune the behavior.
   *
   * @default {triggerOnce:false}
   * @see https://www.npmjs.com/package/react-intersection-observer#options
   */
  intersectionOptions?: IntersectionOptions;

  // -- Advanced Props --

  callbacks?: Partial<{
    onInViewChange: (
      inView: boolean,
      entry?: IntersectionObserverEntry,
    ) => void;
    onEntering: (node: HTMLElement, isAppearing: boolean) => void;
    onEntered: (node: HTMLElement, isAppearing: boolean) => void;
    onExit: (node: HTMLElement) => void;
    onExiting: (node: HTMLElement) => void;
    onExited: (node: HTMLElement) => void;
  }>;

  /**
   * Page transitions: automates fade-out when one of the page-exit functions
   * was used to wrap a link. Note that this is ONE WAY and made for one-time
   * page content, not persistent elements like nav items!
   * @see page-exit.ts
   */
  withPageExit?: boolean;
  /**
   * Modifies the timeout value passed to the Transition
   */
  timeout?: number;
  /**
   * Cancel fade on this instance
   */
  noFade?: boolean;
};

/**
 * version: 3.0.0
 *
 * Fade with extras like shift, scale, stagger, and built-in in-view functionality.
 *
 * By default children are cloned to add style props, which helps
 * with lists, such as:
 *
 * <ul>
 *   <Fade stagger={0.5}>
 *     <li>...</li>
 *     <li>...</li>
 *     <li>...</li>
 *   </Fade>
 * </ul>
 *
 * That works for plain elements, but in other cases a per-child div wrapper is
 * added: custom function components, plain string children, childre containing a ref,
 * and forwardRef components. To avoid this you can either wrap the child
 * with an extra div that you style, or set the special `fade-wrapper-style` prop
 * directly on your custom component, which works like the React `style` prop.
 */
const Fade: FC<FadeProps> = ({
  isShowing = true,
  intersectionOptions,
  seconds = defaults.seconds,
  delaySeconds = 0,
  enterEasing, // default is set below
  stagger,
  exitEasing = defaults.exitEasing,
  xShift = 0,
  yShift = 0,
  xScale = 1,
  yScale = 1,
  customTransform,
  customStyles,
  wrapperCss,
  children,
  callbacks,
  timeout,
  withPageExit = false,
  noFade,
}) => {
  const { isExitingPage } = usePageExit(withPageExit);
  const prefersReducedMotion = useReducedMotion();

  const options: IntersectionOptions = intersectionOptions
    ? { ...defaults.intersectionOptions, ...intersectionOptions }
    : defaults.intersectionOptions;

  let transition = 'opacity';
  const hasShift = !!xShift || !!yShift;
  const _enterEasing =
    enterEasing ||
    (hasShift ? defaults.enterEasingShift : defaults.enterEasing);

  // Build transforms - please preserve the preceding spaces in strings
  let startTransform = '';
  let endTransform = '';
  if (hasShift) {
    startTransform += `translate(${cssVal(xShift)}, ${cssVal(yShift)})`;
    endTransform += 'translate(0, 0)';
  }

  const hasScale = (!!xScale && xScale !== 1) || (!!yScale && yScale !== 1);
  if (hasScale) {
    startTransform += ` scale(${xScale}, ${yScale})`;
    endTransform += ' scale(1, 1)';
  }
  if (customTransform) {
    startTransform += ` ${customTransform}`;
    endTransform += ` ${customTransform}`;
  }
  if (startTransform || endTransform) {
    transition += ' transform';
  }
  startTransform ||= 'unset';
  endTransform ||= 'unset';

  const startOpacity = noFade ? 1 : 0;
  const endOpacity = 1;
  // Only apply transforms if there actually are any -- otherwise, you risk boxing in any position:absolute children
  const transitionStyles = {
    entering: {
      opacity: startOpacity,
      transform: startTransform,
      ...customStyles,
    },
    entered: {
      opacity: endOpacity,
      transform: endTransform,
    },
    exiting: {
      opacity: startOpacity,
      transform: startTransform,
      ...customStyles,
    },
    exited: {
      opacity: startOpacity,
      transform: startTransform,
    },
  };

  const canShow = !isExitingPage && isShowing;

  /*
  Each child gets its own InView watcher and since v3, its own Transition
  as well, since it's not possible to manage multiple inView states for a
  single Transition at render-time. Some elements get wrapped in a div.
   */
  const mappedChildren = Children.map(children, (child, i) => {
    // Skip null child or nested Fade instance
    if (!child || child['type']['name'] === 'Fade') {
      return child;
    }

    return (
      <InView {...options} onChange={callbacks?.onInViewChange}>
        {({ inView, ref: inViewRef }) => (
          <Transition
            appear // needed to show immediately in options.skip case
            in={canShow && (inView || options.skip)}
            mountOnEnter={false} // Don't set true, that adds in-view complexity,
            unmountOnExit={false} // and objects usually should hold their size.
            timeout={
              timeout !== undefined ? timeout : (seconds + delaySeconds) * 1000
            }
            {...callbacks}
          >
            {(state) => {
              const isEnter = state.includes('enter');
              const style = {
                ...child['props']['style'],
                ...child['props']['fade-wrapper-style'],
                transitionProperty: transition,
                transitionTimingFunction: `${
                  isEnter ? _enterEasing : exitEasing
                }`,
                transitionDuration: `${prefersReducedMotion ? 0 : seconds}s`,
                transitionDelay:
                  stagger === undefined || i === undefined
                    ? `${delaySeconds}s`
                    : `${delaySeconds + i * seconds * stagger}s`,
                ...transitionStyles[state],
              };

              // We can clone an element directly if it's not raw text and:
              return isValidElement(child) &&
                // it doesn't have a ref (we'll need a wrapper for inView ref in this case)
                !child['ref'] &&
                // it's not a custom function component (those don't have `ref`)
                typeof child.type !== 'function' &&
                // and it's not a forwardRef component (can't currently be cloned).
                // This is the way the `react-is` package sniffs for forwardRef
                child.type?.['$$typeof'] !== Symbol.for('react.forward_ref') ? (
                cloneElement(
                  child,
                  {
                    ...child.props,
                    style,
                    ref: inViewRef,
                  },
                  child.props?.children,
                )
              ) : (
                // wrapper can be customized with `fade-wrapper-style` prop
                <div
                  key={`.${i}`}
                  style={style}
                  // Twin-specific wrapper css
                  css={[wrapperCss, child['props']['fade-wrapper-css']]}
                  ref={inViewRef}
                >
                  {child}
                </div>
              );
            }}
          </Transition>
        )}
      </InView>
    );
  });

  return <>{mappedChildren}</>;
};

export { Fade };

const cssVal = (val?: string | number) =>
  typeof val === 'string' ? val : (val ?? 0) + 'px';
