import { Interpolation, Theme } from '@emotion/react';
import React, { FC, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import tw from 'twin.macro';
import { Fade } from '~/components/common';
import { DesktopNav } from '~/components/common/Navigation/DesktopNav';
import { AudioFile } from '~/config';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { useHasMounted } from '~/hooks';

type LayoutProps = {
  customCss?: Interpolation<Theme>; // additional styles in any Twin format (css, tw, style object, array)
  noHeader?: boolean;
  noSideNav?: boolean;
  isPoemPageScrolled?: boolean;
  footer?: boolean;
  stickyFooter?: boolean;
  audio?: AudioFile;
  readBy?: string;
  onMobileMenuChange?: (boolean) => void;
};

export const Layout: FC<LayoutProps> = ({
  noHeader,
  noSideNav,
  isPoemPageScrolled,
  footer,
  stickyFooter,
  audio,
  readBy,
  children,
  onMobileMenuChange,
  customCss,
}) => {
  const hasMounted = useHasMounted();
  footer = footer ?? stickyFooter;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMobileOpen = (open: boolean) => {
    setMobileOpen(open);
    if (onMobileMenuChange) {
      onMobileMenuChange(open);
    }
  };

  return (
    <div
      css={[
        tw`relative [min-height:100vh]`,
        isPoemPageScrolled ? tw`bg-black text-white` : tw`bg-white text-black`,
        customCss,
      ]}
    >
      {!noHeader && (
        <Header
          isPoemPageScrolled={isPoemPageScrolled}
          audio={audio}
          readBy={readBy}
          onMobileOpen={handleMobileOpen}
          mobileOpen={mobileOpen}
        />
      )}
      <Fade withPageExit>
        {hasMounted && !noSideNav && !isMobileOnly && (
          <DesktopNav customCss={tw`hidden md:(block m-8)`} />
        )}
        {children}
      </Fade>
      {hasMounted && footer && !isMobileOnly && (
        <Fade withPageExit>
          <Footer sticky={stickyFooter} />
        </Fade>
      )}
    </div>
  );
};
