import React, { FC } from 'react';
import tw, { css } from 'twin.macro';
import { LinkPlus } from '~/components/common';
import { CustomCssProps } from '~/config';
import { navData } from '~/config/nav';
import LinkIcon from '~/images/link-out.svg';
import { getPageExitClick } from '../transitions/page-exit';

type DesktopNavProps = CustomCssProps & {
  //
};

export const DesktopNav: FC<DesktopNavProps> = ({ customCss }) => {
  return (
    <Header css={customCss}>
      <UL>
        {navData.map((item) => (
          <LinkPlus
            key={item.to}
            to={item.to}
            onClick={getPageExitClick(item.to)}
            customCss={linkStyle}
            activeClassName="active"
          >
            {item.text}
            {item.to.includes('http') && <LinkIcon />}
          </LinkPlus>
        ))}
      </UL>
    </Header>
  );
};
// Using tailwind bg-transparency causes a transition to transparent black rather than transparent white
const Header = tw.header`flex flex-col fixed z-40 [background:linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))]`;
const UL = tw.ul`flex flex-col gap-1 [padding:0 2.5rem 0.5rem 0.25rem]`;

const linkStyle = css`
  position: relative;
  text-transform: capitalize;
  display: flex;

  &.active {
    left: 2rem;
  }

  &.active:after {
    position: absolute;
    content: '';
    left: -2rem;
    height: 1rem;
    margin: auto 0;
    bottom: 0;
    top: 0;
    width: 1.5rem;
    background: rgb(var(--brand-gray-light));
  }
`;
