import { useEffect, useRef, useState } from 'react';
import { isSSG } from '~/config/consts';

export type ViewportData = {
  viewportWidth: number;
  viewportHeight: number;
};

/**
 * A custom hook which wraps window resize event registration.
 * This improves ergonomics and ensures cleanup is always handled appropriately.
 *
 * Returns bindable references to the viewport dimensions and accepts an optional
 * handler invoked on window resize.
 *
 * @param handler IMPORTANT: Wrap in useCallback. Called on every resize event, as well as once immediately
 * @param debounceMs pass a number of ms to add debounce
 * @param deps    List of dependencies used within the handler
 * @returns { viewportWidth: number, viewportHeight: number }
 */
export function useResize(
  /* wrap handler in useCallback! */
  handler?: (data: ViewportData) => void,
  debounceMs?: number,
) {
  const timeout = useRef<NodeJS.Timeout>();
  const [viewportWidth, setViewportWidth] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  useEffect(() => {
    if (isSSG) {
      return;
    }
    const handleResize = (e?) => {
      const update = () => {
        setViewportWidth(window.innerWidth);
        setViewportHeight(window.innerHeight);
        if (handler) {
          handler({
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight,
          });
        }
      };

      if (e && debounceMs) {
        clearTimeout(timeout.current!);
        timeout.current = setTimeout(update, debounceMs);
      } else {
        update();
      }
    };

    window.addEventListener('resize', handleResize, false);

    // Fire initial invocation
    setTimeout(handleResize, 500);

    return () => {
      clearTimeout(timeout.current!);
      window.removeEventListener('resize', handleResize, false);
    };
  }, [debounceMs, handler]);

  return { viewportWidth, viewportHeight };
}
