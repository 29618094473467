import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useSponsorQuery } from '~/hooks/useSponsorQuery';
import { DesktopOnly, Fade, LinkPlus } from '../common';
import { getPageExitClick } from '../common/transitions/page-exit';

type HomeContentProps = {
  isReady: boolean;
  setIsReady: React.Dispatch<React.SetStateAction<boolean>>;
  isShowing: boolean;
};

export const HomeContent: FC<HomeContentProps> = ({
  isReady,
  setIsReady,
  isShowing,
}) => {
  // This is to avoid image not loading or animation not starting correctly when
  // returning to same poem a 2nd time from another page
  // Throws a React console error the first time which is a GatsbyImage bug.
  // Warning: Unknown event handler property `onStartLoad`. It will be ignored.
  const handleStartLoad = (props: { wasCached?: boolean | undefined }) => {
    setIsReady(!!props.wasCached);
  };

  const handleLoad = () => setIsReady(true);

  const {
    projectByItems,
    projectByImgs,
    mostPreciousSponsors,
    mostPreciousSponsorsImgs,
  } = useSponsorQuery();

  return (
    <OuterContainer>
      <Fade seconds={0.25} isShowing={isShowing} withPageExit>
        <Container fade-wrapper-style={{ height: '100%' }}>
          <Row>
            <DesktopOnly>
              <h1 tw="text-brand-gray inline-block w-auto font-bold text-4xl mr-1">
                Dicta
              </h1>
              <h2 tw="text-brand-gray inline-block w-auto ml-1 text-base">
                legal poetry by <strong>harbani ahuja</strong>
              </h2>
            </DesktopOnly>
            <h3 tw="text-brand-gray text-xl leading-6 sm:(mt-4)">
              Poetry of the legal cases that have
              <span tw="inline-block bg-black text-white whitespace-nowrap font-medium ml-1 mt-1 pb-2 pl-3 pr-9 lg:(mt-0)">
                shaped our nation
              </span>
            </h3>
          </Row>
          <Row tw="flex justify-between gap-8 md:(justify-center)">
            {projectByItems.map(
              (node, i) =>
                projectByImgs[i] && (
                  <ProjectByContainer key={node.slug} i={i}>
                    <div>
                      {node.level === 'projectBy' ? 'Project' : 'Powered'} by:
                    </div>
                    <LinkPlus to={node.url}>
                      <GatsbyImage
                        tw="[max-width:150px] [max-height:calc(150px * 0.3)] md:([max-width:200px] [max-height:calc(200px * 0.3)])"
                        image={projectByImgs[i]! as IGatsbyImageData}
                        objectFit="contain"
                        objectPosition="left"
                        alt={node.title}
                        onStartLoad={handleStartLoad}
                        onLoad={handleLoad}
                      />
                    </LinkPlus>
                  </ProjectByContainer>
                ),
            )}
          </Row>
          <AutumnalRainbow />
          <Row tw="bg-brand-gray-light m-0! px-2 h-full flex flex-col justify-around">
            <div>With generous support from:</div>
            <SupporterContainer>
              {mostPreciousSponsors.map((node, i) => {
                const level = parseInt(node.level, 10);
                const sponsorSize = sponsorSizes(level, 3);
                const sponsorCss = [
                  tw`w-min`,
                  // add a dividing line between levels
                  i > 0 &&
                    mostPreciousSponsors[i - 1].level !== node.level &&
                    tw`border-l-2 [border-color:'rgba(var(--brand-gray), 0.8)']`,
                ];
                const sponsorImage = (
                  <GatsbyImage
                    css={sponsorSize}
                    image={mostPreciousSponsorsImgs[i]!}
                    objectFit="contain"
                    alt={node.title}
                    onStartLoad={handleStartLoad}
                    onLoad={handleLoad}
                  />
                );
                return (
                  mostPreciousSponsorsImgs[i] &&
                  (node.url ? (
                    <LinkPlus
                      key={node.slug}
                      to={node.url}
                      customCss={sponsorCss}
                    >
                      {sponsorImage}
                    </LinkPlus>
                  ) : (
                    <div key={node.slug} css={sponsorCss}>
                      {sponsorImage}
                    </div>
                  ))
                );
              })}
            </SupporterContainer>
          </Row>
          <AutumnalRainbow />
          <Row>
            <LinkPlus
              customCss={tw`bg-black text-white px-14 py-3 font-bold`}
              to="/timeline/"
              onClick={getPageExitClick('/timeline/')}
            >
              Begin
            </LinkPlus>
          </Row>
        </Container>
      </Fade>
    </OuterContainer>
  );
};

const OuterContainer = tw.div`w-screen h-full landscape:[max-height: 1200px]`;
const Container = tw.div`h-full
  grid pb-10 pt-2 items-center text-brand-gray 
  [grid-template-rows:minmax(4rem, 6rem) minmax(4rem, 6rem) 4px auto 4px auto]
  md:([grid-template-rows:minmax(18vh, auto) auto 4px auto 4px auto])`;
const Row = tw.div`mx-16 md:(mx-20) text-center`;
const ProjectByContainer = styled.div<{ i: number }>(({ i }) => [
  tw`text-left text-sm`,
  i === 0 && tw`md:(border-r-2)`,
]);
const SupporterContainer = tw.div`w-full flex gap-4 items-center justify-center flex-wrap mb-2`;

export const AutumnalRainbow = tw.div`h-2 w-full 
  bg-gradient-to-r from-brand-gray 
  [--tw-gradient-stops:rgb(var(--brand-earth)) 20%,
    rgb(var(--brand-coral)) 20%, rgb(var(--brand-coral)) 40%,
    rgb(var(--brand-red-delicious)) 40%, rgb(var(--brand-red-delicious)) 60%,
    rgb(var(--brand-pumpkin)) 60%, rgb(var(--brand-pumpkin)) 80%,
    rgb(var(--brand-sky-blue)) 80%, rgb(var(--brand-sky-blue))]`;

/**
 * Progressive size scaling for sponsor logos based upon their level
 * @param level the level of this sponsor
 * @param max the total number of levels to be considered
 * @default minWidthPx 300px
 * @default maxWidthVw 40vw
 * @default maxHeightPx 250px
 * @default breakpointPx 640px
 * Size defaults are for home-content -- please don't change them
 */
export const sponsorSizes = (
  level: number,
  max: number,
  minWidthPx = 340,
  maxWidthVw = 40,
  maxHeightPx = 200,
  breakpointPx = 640,
) => {
  const adjustedLevel = level + Math.log(level / 1.9);
  const sizeFactor = (2 * max - adjustedLevel - 1) / (max + 1);
  return [
    tw`mx-4`,
    css`
      min-width: ${sizeFactor * minWidthPx}px;
      width: ${sizeFactor * maxWidthVw * 0.8}vw;

      max-height: ${sizeFactor * maxHeightPx * 0.8}px;

      @media (min-width: ${breakpointPx}px) {
        min-width: ${sizeFactor * minWidthPx}px;
        width: ${sizeFactor * maxWidthVw}vw;
        max-width: ${sizeFactor * 180}px;

        max-height: ${sizeFactor * maxHeightPx}px;
      }
    `,
  ];
};
